// Three related imports
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls';
import Stats from 'three/addons/libs/stats.module.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';
import { FontLoader } from 'three/addons/loaders/FontLoader';
import { TextGeometry } from 'three/addons/geometries/TextGeometry';
import { Sky } from 'three/addons/objects/Sky';

// Post-processing
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { OutlinePass } from 'three/addons/postprocessing/OutlinePass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';

// Vendor imports
import { effect, signal } from '@preact/signals-core';
import { gsap } from 'gsap';

// Local imports
import {
  CITY_MODEL_URLS,
  CITY_TEXTURE_URLS,
  PRIMARY_FONT_URL,
  CLICK_SOUND_URL,
  TRAFFIC_SOUND_URL,
  WHOOSH_SOUND_URL,
} from './consts/resources';
import { CITY_MESH_NAME, CITY_MESH_NAMES, SOUND_NAME, PAGE, IDLE_PAGES } from './consts/commons';

// Enable cache
THREE.Cache.enabled = true;

// States
const currentPage = signal(PAGE.loading);
const isMuted = signal(false);
const isPortrait = signal(getIsPortrait());
const sound = signal({ click: null, traffic: null, whoosh: null });

// HTML elements
const canvas = document.querySelector('#canvas');
const loadingBar = document.querySelector('#loading-bar');
const container = document.querySelector('#container');
const soundButton = container.querySelector('#sound-button');
const enterButton = container.querySelector('#enter-button');

// Three elements
const audioListener = new THREE.AudioListener();
const audioLoader = new THREE.AudioLoader();
const loadingManager = new THREE.LoadingManager();
const raycaster = new THREE.Raycaster();
const scene = new THREE.Scene();
const textureLoader = new THREE.TextureLoader();
const cursor = new THREE.Vector2();
const fontLoader = new FontLoader();
const camera = createCamera();
const control = createControl({ camera, canvas });
const renderer = createRenderer({ canvas });
const stats = createStats();

// Post-processing
const size = renderer.getDrawingBufferSize(new THREE.Vector2());
const opt = { samples: 4, type: THREE.HalfFloatType };
const renderTarget = new THREE.WebGLRenderTarget(size.width, size.height, opt);
const composer = new EffectComposer(renderer, renderTarget);
setupRenderPass();
const outlinePass = createOutlinePass();
setupOutputPass();

// Icons
const volumeOnIcon = createVolumeOnIcon();
const volumeOffIcon = createVolumeOffIcon();

// Watch states change
effect(() => {
  if (currentPage.value === PAGE.loading) {
    updateContainerStyle({ container, key: 'display', value: 'flex' });
  }

  if (currentPage.value === PAGE.main) {
    updateContainerStyle({ container, key: 'zIndex', value: 0 });
  }

  if (currentPage.value === PAGE.playground) {
    updateContainerStyle({ container, key: 'height', value: 'initial' });
    playSound({ name: SOUND_NAME.traffic });
    control.enabled = true;
  }

  if (isMuted.value) {
    switchButtonIcon({ button: soundButton, icon: volumeOffIcon });
    muteSounds();
  } else {
    switchButtonIcon({ button: soundButton, icon: volumeOnIcon });
  }
});

// Load objects
loadSkyAndSun();
loadCity();
loadSounds();

// Init event listeners
window.addEventListener('resize', onWindowResize);
canvas.addEventListener('mouseup', onMouseUp);
canvas.addEventListener('mousedown', onMouseDown);
canvas.addEventListener('mousemove', onMouseMove);
canvas.addEventListener('click', onClickCity);
soundButton.addEventListener('click', onClickSoundButton);
enterButton.addEventListener('click', onClickEnterButton);

// Animate
tick();

function tick() {
  window.requestAnimationFrame(tick);

  control.update();
  stats.update();

  // console.log('control', control.target);
  // console.log('camera', camera.position);
  render();
}

function setupRenderPass() {
  const renderPass = new RenderPass(scene, camera);
  composer.addPass(renderPass);
}

function createOutlinePass() {
  const outlinePass = new OutlinePass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    scene,
    camera
  );

  outlinePass.edgeStrength = 15;
  outlinePass.edgeGlow = 1;
  outlinePass.edgeThickness = 5;
  outlinePass.pulsePeriod = 5;
  outlinePass.usePatternTexture = false;
  outlinePass.visibleEdgeColor.set('#514b82');
  outlinePass.hiddenEdgeColor.set('#190a05');

  composer.addPass(outlinePass);

  return outlinePass;
}

function setupOutputPass() {
  const outputPass = new OutputPass();
  composer.addPass(outputPass);
}

function render() {
  composer.render();
}

function createRenderer({ canvas }) {
  const renderer = new THREE.WebGLRenderer({ canvas });
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.5;

  return renderer;
}

function createCamera() {
  // Camera options
  const fov = 45;
  const aspect = window.innerWidth / window.innerHeight;
  const near = 0.1;
  const far = isPortrait.value ? 175 : 100;
  const cameraOptions = [fov, aspect, near, far];

  const camera = new THREE.PerspectiveCamera(...cameraOptions);
  camera.position.set(0, 0, isPortrait.value ? 150 : 75);
  camera.add(audioListener);

  scene.add(camera);
  return camera;
}

function createControl({ camera, canvas }) {
  const control = new OrbitControls(camera, canvas);
  control.target.set(0, 0, 0);
  control.enabled = false;
  control.enablePan = false;
  control.enableDamping = true;
  control.dampingFactor = 0.05;
  control.minPolarAngle = 0;
  control.maxPolarAngle = Math.PI * 0.5;
  control.minDistance = 5;
  control.maxDistance = isPortrait.value ? 150 : 75;

  return control;
}

function createStats() {
  const stats = new Stats();
  stats.dom.style.top = 'unset';
  stats.dom.style.left = 'unset';
  stats.dom.style.bottom = 0;
  stats.dom.style.right = 0;
  container.appendChild(stats.dom);

  return stats;
}

// Event listeners
function onWindowResize() {
  isPortrait.value = getIsPortrait();

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  composer.setSize(window.innerWidth, window.innerHeight);
}

function onMouseUp() {
  setMouseCursor({ type: 'grab' });
}

function onMouseDown() {
  setMouseCursor({ type: 'grabbing' });
}

let debounceTimer;
function onMouseMove(event) {
  // Prevent click on idle pages
  if (IDLE_PAGES.includes(currentPage.value)) return;

  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => {
    cursor.x = (event.clientX / window.innerWidth) * 2 - 1;
    cursor.y = -(event.clientY / window.innerHeight) * 2 + 1;

    const mesh = checkIntersection();
    if (!mesh) {
      setMouseCursor({ type: 'grab' });
    } else {
      setMouseCursor({ type: 'pointer' });
      setOutlinedMeshes({ meshes: [mesh] });
    }
  }, 100);
}

function onClickCity(event) {
  // Prevent click on idle pages
  if (IDLE_PAGES.includes(currentPage.value)) return;

  cursor.x = (event.clientX / window.innerWidth) * 2 - 1;
  cursor.y = -(event.clientY / window.innerHeight) * 2 + 1;

  navigateCity();
}

function checkIntersection() {
  raycaster.setFromCamera(cursor, camera);

  const objects = createClickableMeshes();
  const intersects = raycaster.intersectObjects(objects, false);
  if (intersects.length < 1) return;

  const selected = intersects[0];
  if (!selected) return;

  const meshName = selected.object?.name;
  const clickableMeshNames = createClickableMeshNames();
  if (!clickableMeshNames.includes(meshName)) return;

  return selected.object;
}

function onClickSoundButton() {
  playSound({ name: SOUND_NAME.click });

  setTimeout(() => (isMuted.value = !isMuted.value), 250);
}

function onClickEnterButton() {
  enterButton.disabled = true;

  const mesh = findMeshByName({ name: CITY_MESH_NAME.aOffice });
  if (!mesh) return;

  navigateCity(mesh);
  clearMainScreen();
}

// Loaders
function createDracoLoader() {
  const dracoLoader = new DRACOLoader();

  dracoLoader.setDecoderPath('draco/');

  return dracoLoader;
}

function createGltfLoader(option) {
  const dracoLoader = createDracoLoader();
  const gltfLoader = new GLTFLoader(option?.loadingManager);

  gltfLoader.setDRACOLoader(dracoLoader);

  return gltfLoader;
}

// Geometry, material, texture, and mesh related
function createTextGeometry(text, option) {
  const textGeometry = new TextGeometry(text, option);

  return textGeometry;
}

function createMeshBasicMaterial({ map, color }) {
  const option = { map, color };

  if (map) {
    delete option.color;
  }

  if (color) {
    delete option.map;
  }

  const meshBasicMaterial = new THREE.MeshBasicMaterial(option);

  return meshBasicMaterial;
}

function createBakedMaterial({ url }) {
  const texture = textureLoader.load(url);

  texture.colorSpace = THREE.SRGBColorSpace;
  texture.flipY = false;

  const material = createMeshBasicMaterial({ map: texture });

  return material;
}

function createMesh({ geometry, material }) {
  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
}

// UI objects
function loadSkyAndSun() {
  const sky = new Sky();
  sky.scale.setScalar(450000);

  const sun = new THREE.Vector3();

  const effectController = {
    turbidity: 10,
    rayleigh: 3,
    mieCoefficient: 0.005,
    mieDirectionalG: 0.7,
    elevation: 3,
    azimuth: 140,
    exposure: renderer.toneMappingExposure,
  };
  const phi = THREE.MathUtils.degToRad(90 - effectController.elevation);
  const theta = THREE.MathUtils.degToRad(effectController.azimuth);
  const uniforms = sky.material.uniforms;

  sun.setFromSphericalCoords(1, phi, theta);
  uniforms['sunPosition'].value.copy(sun);

  renderer.toneMappingExposure = effectController.exposure;

  scene.add(sky);
}

function loadCity() {
  loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
    if (itemsLoaded % 2 === 0) {
      const progress = itemsLoaded / itemsTotal;
      showLoadingBar({ progress });
    }
  };

  loadingManager.onLoad = function () {
    showMainScreen();
  };

  loadingManager.onError = function (url) {
    console.error(`Error loading ${url}`);
  };

  loadCityMeshes();
}

async function loadMainTitle() {
  const font = await fontLoader.loadAsync(PRIMARY_FONT_URL);
  const mainMaterial = createMeshBasicMaterial({ color: '#422800' });
  const shadowMaterial = createMeshBasicMaterial({ color: '#ffffff' });
  const geometry = createTextGeometry('Jadi Dev', {
    font: font,
    size: 0.5,
    depth: 0.2,
    curveSegments: 12,
    bevelEnabled: false,
    bevelThickness: 0.03,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
  });
  geometry.center();

  const mainTextMesh = createMesh({ geometry, material: mainMaterial });
  const shadowTextMesh = createMesh({ geometry, material: shadowMaterial });

  mainTextMesh.position.set(
    isPortrait.value ? -93 : -41,
    isPortrait.value ? 21.55 : 9.3,
    isPortrait.value ? 93.05 : 41.05
  );
  mainTextMesh.rotation.set(0, -(45 * Math.PI) / 180, 0);
  mainTextMesh.name = 'mainTextMesh';
  shadowTextMesh.position.set(
    isPortrait.value ? -93 : -41,
    isPortrait.value ? 21.5 : 9.25,
    isPortrait.value ? 93 : 41
  );
  shadowTextMesh.rotation.set(0, -(45 * Math.PI) / 180, 0);
  shadowTextMesh.name = 'shadowTextMesh';

  scene.add(mainTextMesh, shadowTextMesh);
}

async function loadCityMeshes() {
  try {
    const materials = CITY_TEXTURE_URLS.map(url => createBakedMaterial({ url }));

    // Ensure to load ground (road/stree) first before buildings
    const groundUrl = CITY_MODEL_URLS[0];
    const groundMaterial = materials[0];
    await createGroundMeshes({ url: groundUrl, material: groundMaterial });

    // Then load buildings on top of ground
    const buildingUrls = CITY_MODEL_URLS.slice(1);
    const buildingMaterials = materials.slice(1);
    const buildingPromises = buildingUrls.map((url, idx) => {
      return createBuildingPromises({ idx, url, materials: buildingMaterials });
    });
    await Promise.all(buildingPromises);
  } catch (error) {
    console.error('Error loading city scenes', error);
  }
}

async function createGroundMeshes({ url, material }) {
  const gltfLoader = createGltfLoader();
  const gltf = await gltfLoader.loadAsync(url);
  const groundScene = gltf.scene;

  const groundMeshes = groundScene.children.map(child => {
    child.material = material;
    return child;
  });

  scene.add(groundScene);

  return groundMeshes;
}

function createBuildingPromises({ idx, url, materials }) {
  const gltfLoader = createGltfLoader({ loadingManager });

  return new Promise(async resolve => {
    const gltf = await gltfLoader.loadAsync(url);

    const buildingScene = gltf.scene;
    const buildingMeshes = buildingScene.children.map(child => {
      if (child.name === 'b-pecel-banner') {
        child.material = materials[materials.length - 1];
      } else {
        child.material = materials[idx];
      }

      return child;
    });

    scene.add(buildingScene);

    resolve(buildingMeshes);
  });
}

function navigateCity(initialMesh) {
  const mesh = initialMesh || checkIntersection();
  if (!mesh) return;

  setMouseCursor({ type: 'pointer' });

  switch (mesh.name) {
    case CITY_MESH_NAME.aOffice:
      showOfficeSection({ mesh });
      break;
    case CITY_MESH_NAME.bMasjid:
      showMasjidSection({ mesh });
      break;
    case CITY_MESH_NAME.bPecelBanner:
    case CITY_MESH_NAME.bPecel:
      showPecelSection({ mesh });
      break;
    case CITY_MESH_NAME.bSempol:
      showSempolSection({ mesh });
      break;
    case CITY_MESH_NAME.cHome:
      showHomeSection({ mesh });
      break;
    case CITY_MESH_NAME.cTheater:
      showTheaterSection({ mesh });
      break;
    case CITY_MESH_NAME.dHotel:
      showHotelSection({ mesh });
      break;
  }
}

function showLoadingBar({ progress }) {
  loadingBar.style.display = 'block';
  loadingBar.style.setProperty('--scale-x', progress);
}

function showMainScreen() {
  goTo({
    cameraPosition: {
      x: isPortrait.value ? -100 : -45,
      y: isPortrait.value ? 23 : 10,
      z: isPortrait.value ? 100 : 45,
    },
    delay: 1,
    onStart() {
      loadingBar.remove();
    },
    async onComplete() {
      await loadMainTitle();
      setCurrentPage(PAGE.main);
    },
  });
}

function clearMainScreen() {
  // Remove and dispose main title and enter button from scene
  const mainTextMesh = scene.children.find(child => child.name === 'mainTextMesh');
  const shadowTextMesh = scene.children.find(child => child.name === 'shadowTextMesh');
  scene.remove(mainTextMesh, shadowTextMesh);
  mainTextMesh.geometry.dispose();
  mainTextMesh.material.dispose();
  shadowTextMesh.geometry.dispose();
  shadowTextMesh.material.dispose();
  enterButton.remove();
}

function showMap() {
  control.target.set(0, 0, 0);
  camera.position.set(0, isPortrait.value ? 130 : 60, 0);

  const meshes = createClickableMeshes();
  setOutlinedMeshes({ meshes });
}

function showOfficeSection({ mesh }) {
  goTo({
    controlPosition: { x: 3.2, y: 0.7, z: -3.1 },
    cameraPosition: {
      x: isPortrait.value ? 18.17 : 15.7,
      y: isPortrait.value ? 4.18 : 3.3,
      z: isPortrait.value ? 25.7 : 19.2,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setCurrentPage(PAGE.playground);
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

function showMasjidSection({ mesh }) {
  goTo({
    controlPosition: { x: -12.1, y: 0.3, z: 0.9 },
    cameraPosition: {
      x: isPortrait.value ? 3.63 : -0.5,
      y: isPortrait.value ? 3.48 : 2.6,
      z: isPortrait.value ? 22.44 : 16.3,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

function showPecelSection({ mesh }) {
  goTo({
    controlPosition: { x: -7.1, y: -0.06, z: 0.6 },
    cameraPosition: {
      x: isPortrait.value ? -17.77 : -16.1,
      y: isPortrait.value ? 0.84 : 0.7,
      z: isPortrait.value ? 7.83 : 6.7,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

function showSempolSection({ mesh }) {
  goTo({
    controlPosition: { x: -8.1, y: -0.7, z: 0.7 },
    cameraPosition: {
      x: isPortrait.value ? -17.79 : -16.7,
      y: isPortrait.value ? 0.53 : 0.4,
      z: isPortrait.value ? 3.4 : 3.1,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

function showHomeSection({ mesh }) {
  goTo({
    controlPosition: { x: 6.8, y: 1.7, z: -2.7 },
    cameraPosition: {
      x: isPortrait.value ? 17.37 : 16.8,
      y: isPortrait.value ? 2.12 : 2.1,
      z: isPortrait.value ? -18.93 : -17.8,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

function showTheaterSection({ mesh }) {
  goTo({
    controlPosition: { x: -3.3, y: 3.5, z: 0.6 },
    cameraPosition: {
      x: isPortrait.value ? -9.57 : -7.5,
      y: isPortrait.value ? 4.32 : 4.0,
      z: isPortrait.value ? 4.97 : 3.3,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

function showHotelSection({ mesh }) {
  goTo({
    controlPosition: { x: -8.9, y: 2.7, z: -1.4 },
    cameraPosition: {
      x: isPortrait.value ? -15.16 : -14.2,
      y: isPortrait.value ? 3.52 : 3.6,
      z: isPortrait.value ? 8.16 : 4.9,
    },
    onStart() {
      playSound({ name: SOUND_NAME.click });
      setTimeout(() => playSound({ name: SOUND_NAME.whoosh }), 500);
    },
    onComplete() {
      setOutlinedMeshes({ meshes: [mesh] });
    },
  });
}

// Sounds related
async function loadSounds() {
  const clickSound = await createClickSound();
  const trafficSound = await createTrafficSound();
  const whooshSound = await createWhooshSound();

  sound.value = { click: clickSound, traffic: trafficSound, whoosh: whooshSound };
}

async function createClickSound() {
  const sound = new THREE.Audio(audioListener);
  const buffer = await audioLoader.loadAsync(CLICK_SOUND_URL);

  sound.setBuffer(buffer);
  sound.setLoop(false);
  sound.setVolume(1);

  return sound;
}

async function createTrafficSound() {
  const sound = new THREE.Audio(audioListener);
  const buffer = await audioLoader.loadAsync(TRAFFIC_SOUND_URL);

  sound.setBuffer(buffer);
  sound.setLoop(true);
  sound.setVolume(0.1);

  return sound;
}

async function createWhooshSound() {
  const sound = new THREE.Audio(audioListener);
  const buffer = await audioLoader.loadAsync(WHOOSH_SOUND_URL);

  sound.setBuffer(buffer);
  sound.setLoop(false);
  sound.setVolume(0.05);
  sound.playbackRate = 0.5;

  return sound;
}

function playSound({ name }) {
  if (isMuted.value) return;
  sound.value[name].play();
}

function muteSounds() {
  sound.value.click.stop();
  sound.value.traffic.stop();
  sound.value.whoosh.stop();
}

// Helpers
function goTo({
  controlPosition = { x: 0, y: 0, z: 0 },
  cameraPosition = { x: 0, y: 0, z: 0 },
  duration = 2,
  delay = 0,
  ease = 'power3.inOut',
  onStart,
  onComplete,
}) {
  gsap.to(control.target, { ...controlPosition, duration, delay, ease });
  gsap.to(camera.position, { ...cameraPosition, duration, delay, ease, onStart, onComplete });
}

function switchButtonIcon({ button, icon }) {
  if (button.hasChildNodes()) {
    button.removeChild(button.firstChild);
  }

  button.appendChild(icon);
}

function updateContainerStyle({ container, key, value }) {
  container.style[key] = value;
}

function getIsPortrait() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isPortrait = width < height && width - height < 0;

  return isPortrait;
}

function setCurrentPage(page) {
  currentPage.value = page;
}

function setOutlinedMeshes({ meshes }) {
  outlinePass.selectedObjects = meshes;
}

function setMouseCursor({ type }) {
  switch (type) {
    case 'grab':
      canvas.style.cursor = 'grab';
      canvas.style.cursor = '-moz-grab';
      canvas.style.cursor = '-webkit-grab';
      break;
    case 'grabbing':
      canvas.style.cursor = 'grabbing';
      canvas.style.cursor = '-moz-grabbing';
      canvas.style.cursor = '-webkit-grabbing';
      break;
    case 'pointer':
      canvas.style.cursor = 'pointer';
      break;
    default:
      canvas.style.cursor = 'default';
      break;
  }
}

function createMeshes() {
  const meshes = scene.children.map(child => child.children).flat();

  return meshes;
}

function isClickable(item) {
  if (typeof item === 'string') {
    return !item.includes('obj') && !item.includes('ground');
  }

  return !item.name.includes('obj') && !item.name.includes('ground');
}

function createClickableMeshes() {
  const meshes = createMeshes();
  const clickableMeshes = meshes.filter(isClickable);

  return clickableMeshes;
}

function createClickableMeshNames() {
  const clickableMeshNames = CITY_MESH_NAMES.filter(isClickable);

  return clickableMeshNames;
}

function findMeshByName({ name }) {
  const meshes = createMeshes();
  const mesh = meshes.find(child => child.name === name);

  return mesh;
}

// Reusable components
function createSVGIcon(iconData) {
  const { width, height, viewBox, elements } = iconData;

  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svgElement.setAttribute('width', width);
  svgElement.setAttribute('height', height);
  svgElement.setAttribute('viewBox', viewBox);

  elements.forEach(elementData => {
    const { type, attribute } = elementData;
    const element = document.createElementNS('http://www.w3.org/2000/svg', type);
    for (const key in attribute) {
      element.setAttribute(key, attribute[key]);
    }
    svgElement.appendChild(element);
  });

  return svgElement;
}

// Icons
function createVolumeOffIcon() {
  const iconData = {
    width: '24',
    height: '24',
    viewBox: '0 0 24 24',
    elements: [
      {
        type: 'line',
        attribute: {
          x1: '3',
          x2: '21',
          y1: '3',
          y2: '21',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
      {
        type: 'polyline',
        attribute: {
          points: '9.9 5.5 12 3 12 21 7 16 2 16 2 9 3 9 7 9 8 9',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
      {
        type: 'path',
        attribute: {
          d: 'M21.4,16a8.5,8.5,0,0,0,1.1-4.2c0-4.3-3.1-7.8-7-7.8',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
      {
        type: 'path',
        attribute: {
          d: 'M15.5,20.5a5.2,5.2,0,0,0,3-1',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
      {
        type: 'path',
        attribute: {
          d: 'M18.3,13a2.7,2.7,0,0,0,.2-1.1,2.9,2.9,0,0,0-3-2.9',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
    ],
  };

  const volumeOffIcon = createSVGIcon(iconData);

  return volumeOffIcon;
}

function createVolumeOnIcon() {
  const iconData = {
    width: '24',
    height: '24',
    viewBox: '0 0 24 24',
    elements: [
      {
        type: 'polygon',
        attribute: {
          points: '2.9 9 6.9 9 11.9 3 11.9 21 6.9 16 1.9 16 1.9 9 2.9 9',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
      {
        type: 'path',
        attribute: {
          d: 'M15.5,19.5a7.3,7.3,0,0,0,7-7.5,7.3,7.3,0,0,0-7-7.5',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
      {
        type: 'path',
        attribute: {
          d: 'M15.5,15a3,3,0,0,0,0-6',
          fill: 'none',
          stroke: '#000000',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '2',
        },
      },
    ],
  };

  const volumeOnIcon = createSVGIcon(iconData);

  return volumeOnIcon;
}
