import { CITY_MESH_NAMES, SOUND_NAME } from './commons';

// Fonts
export const PRIMARY_FONT_URL = 'fonts/permanent_marker_regular.typeface.json';

// Models
export const CITY_MODEL_URLS = CITY_MESH_NAMES.slice(0, CITY_MESH_NAMES.length - 1).map(
  name => `models/${name}.glb`
);

// Sounds
export const CLICK_SOUND_URL = `sounds/${SOUND_NAME.click}.ogg`;
export const TRAFFIC_SOUND_URL = `sounds/${SOUND_NAME.traffic}.ogg`;
export const WHOOSH_SOUND_URL = `sounds/${SOUND_NAME.whoosh}.ogg`;

// Textures
export const CITY_TEXTURE_URLS = CITY_MESH_NAMES.map(name => `textures/${name}.jpg`);
