export const CITY_MESH_NAME = {
  ground: 'ground',
  bSempol: 'b-sempol',
  bPecel: 'b-pecel',
  bObj: 'b-obj',
  bMasjid: 'b-masjid',
  dObj: 'd-obj',
  dHotel: 'd-hotel',
  cObj: 'c-obj',
  cHome: 'c-home',
  cTheater: 'c-theater',
  aObj: 'a-obj',
  aOffice: 'a-office',
  bPecelBanner: 'b-pecel-banner',
};

export const CITY_MESH_NAMES = Object.values(CITY_MESH_NAME);

export const PAGE = {
  loading: 'loading-screen',
  main: 'main-screen',
  playground: 'playground-screen',
};

export const IDLE_PAGES = [PAGE.loading, PAGE.main];

export const SOUND_NAME = { click: 'click', traffic: 'traffic', whoosh: 'whoosh' };
